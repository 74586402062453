var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-text-field',{ref:"quantityTextField",staticClass:"text-box-with-commas",attrs:{"value":_vm.formattedQuantity,"label":"Exact Quantity","outlined":"","rules":[
    () => _vm.quantity > 0 || _vm.quantity == null || 'Please enter a number greater than 0',
    () =>
      _vm.quantity <= 100000 ||
      _vm.quantity == null ||
      'Please enter a number less than 100000',
    v => /^[0-9\,]+$/.test(v) || 'Please use whole numbers only',
    () => _vm.quantity % 1 === 0 || 'Please use whole numbers only'
  ],"data-testid":"quantityTextField"},on:{"focus":function($event){return $event.target.select()},"input":_vm.handleQuantityInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()}}})
}
var staticRenderFns = []

export { render, staticRenderFns }