
import { StringHelper } from '@/helpers/StringHelper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  $refs!: {
    quantityTextField: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  @Watch('quantity')
  onQuantityChanged(val: number) {
    this.$emit('quantityChanged', val);
  }
  /* Data */
  quantity: number = 0;
  /* Async Functions */
  /* Utility Functions */
  focus() {
    this.$refs.quantityTextField.focus();
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }
  setQuantity(val: number) {
    this.quantity = val;
  }
  submit() {
    this.$emit('submit');
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
  /* Getters */
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
}
