
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ItemService } from '@/services/item-service';
import { Item } from '@/store/models/ItemModel';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';

@Component({
  components: { BasicHelpDialog }
})
export default class extends Vue {
  $refs!: {
    HelpDeskDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop() isItem!: boolean;
  /* Store Actions */
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: boolean;
  /* Watchers */
  /* Data */
  doesItemExists: boolean = false;
  /* Utility Functions */
  AddNewItemSelected() {
    this.$emit('AddNewItemSelected');
  }
  goToOrderView() {
    this.$emit('goToOrderView');
  }
  routeToTemplates() {
    this.$router.push({
      name: 'DieTemplates'
    });
  }
  async checkIfItemExists() {
    const response = await ItemService.GetCustomerPartNumbers();
    if (response.data) {
      this.doesItemExists = !!response.data.paginatedPartNumber[0];
    }
  }
  openHelpDesk() {
    console.log('openHelpDesk');
    this.$refs.HelpDeskDialog.openDialog();
  }
  onNewItemClick() {
    if (this.isTransparencyCustomer) {
      this.$router.push({ name: 'AddEditTransparencyItem' });
    } else {
      this.goToOrderView();
    }
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  created() {
    this.checkIfItemExists();
  }
  /* Emmited Functions */
}
