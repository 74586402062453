
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Item } from '../../store/models/ItemModel';
import { ItemService } from '../../services/item-service';
import ItemActions from '@/components/ItemActions.vue';
import ItemAddToCart from '@/components/ItemAddToCart.vue';
import J1lImg from '@/components/J1lImg.vue';
import ItemApprovalIcon from '@/components/Icons/ItemApprovalIcon.vue';
import ItemOrderDialog from '@/components/ItemOrderDialog.vue';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { PaymentService } from '@/services/payment-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import {
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes
} from '@/store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
@Component({
  components: { ItemActions, J1lImg, ItemApprovalIcon, ItemAddToCart, ItemOrderDialog }
})
export default class extends Vue {
  $refs!: {
    ItemActions: HTMLFormElement;
    J1lImg: HTMLFormElement;
    itemAddToCart: HTMLFormElement;
    ItemOrderDialog: HTMLFormElement;
  };

  /* Properties */
  @Prop({ required: true })
  item!: Item;
  @Prop()
  width!: any;

  /* Store Actions */
  @Getter('getAllProcessingImageIds', { namespace: 'items' })
  processingImageItemIds: any;
  @Getter('getDisplayStyle', { namespace: 'customer' })
  displayStyle!: string;
  @Action('AddToShoppingCart', { namespace: 'orderlines' })
  addToShoppingCart!: any;
  @Mutation('setIsPlacingOrder', { namespace: 'orderlines' })
  setIsPlacingOrder!: any;
  /* Watchers */
  @Watch('processingImageItemIds')
  async onProccessItemIdsChange(val: string[]) {
    if (val[0] && val[0] === this.item.Id) {
      this.HasItemBeenProcessed(val[0]);
      if (val) this.isImageLoading = true;
    }
  }
  /* Data */
  itemFileProcessCounter: number = 0;
  isImageLoading: boolean = false;
  shouldDisplayErrorOverlay: boolean = false;
  isGrabbingHiRezImage: boolean = false;
  isRegeneratingHiRezImage: boolean = false;
  quickActionItems: { Icon: string; EventId: number }[] = [
    { Icon: 'mdi-bell', EventId: 0 },
    { Icon: 'mdi-headphones', EventId: 0 }
  ];
  colors: string[] = ['#616161', '#0096ff', '#ec008c', '#ffc800', '#bdbdbd', '#bdbdbd'];
  colorIcons: string[] = [
    'looks_one',
    'looks_two',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6'
  ];
  shouldDisable: boolean = false;
  /* Utility Functions */
  get determineCardColor() {
    switch (this.item.Material.Id) {
      case 2:
        return 'j1l-black';
      case 3:
        return 'bold-blue';
      case 16:
        return 'mintGreen';
      case 5:
        return 'bubble-gum-purple';
      case 7:
      case 10:
        return 'j1lorange';
      case 19:
        return 'mustard';
      default:
        return 'mintGreen';
    }
  }
  async HasItemBeenProcessed(itemId: string) {
    if (this.item.Die.Id === 212) return;
    if (this.itemFileProcessCounter) return;
    this.itemFileProcessCounter = 0;
    let hasBeenProcessed = this.item.HasBeenProcessed;
    let refreshId = setInterval(async () => {
      if (!hasBeenProcessed) {
        const { data: hasItemProcessed } = await ItemService.HasItemBeenProcessed(itemId);
        hasBeenProcessed = hasItemProcessed;
        this.itemFileProcessCounter++;
      }
      if (hasBeenProcessed) {
        clearInterval(refreshId);
        this.itemFileProcessCounter = 0;
        this.$emit('processImage', { itemId });
        setTimeout(() => {
          this.$refs.J1lImg.rerender();
        }, 1000);
        this.isImageLoading = false;
      }
      if (!hasBeenProcessed && this.itemFileProcessCounter >= 20) {
        this.$store.dispatch('items/removeProcessImageItemId', this.item.Id);
        // if this breaks then remove from the queue and add it to the end of the list when regenerate is pressed
        this.shouldDisplayErrorOverlay = true;
        clearInterval(refreshId);
      }
    }, 5000);
  }
  async HasHighRezImageBeenProcessed() {
    this.isRegeneratingHiRezImage = true;
    let hasBeenProcessed = false;
    let itemId = this.item.Id;
    let refreshId = setInterval(async () => {
      if (!hasBeenProcessed) {
        const { data: hasItemProcessed } = await ItemService.HasHighRezImageBeenProcessed(
          itemId
        );
        hasBeenProcessed = hasItemProcessed;
        this.itemFileProcessCounter++;
      }
      if (hasBeenProcessed) {
        clearInterval(refreshId);
        this.itemFileProcessCounter = 0;
        this.$emit('processImage', { itemId });
        this.isRegeneratingHiRezImage = false;
        setTimeout(() => {
          this.$refs.J1lImg.rerender();
        }, 1000);
        this.isImageLoading = false;
      }
      if (!hasBeenProcessed && this.itemFileProcessCounter >= 20) {
        // if this breaks then remove from the queue and add it to the end of the list when regenerate is pressed
        this.isRegeneratingHiRezImage = false;
        this.shouldDisplayErrorOverlay = true;
        clearInterval(refreshId);
      }
    }, 5000);
  }
  openBarcodePlacement() {
    this.$refs.ItemActions.handleEvent('Barcode Placement');
  }
  /* Event Handlers */
  hasPassedErrorWindow() {
    let todaysDate = new Date();
    let dateWindow = new Date(this.item.LastUpdated);
    todaysDate.setDate(todaysDate.getDate() - 5);
    return dateWindow < todaysDate;
  }
  async RegenerateFileGraphics() {
    this.shouldDisplayErrorOverlay = false;
    // await ItemService.RegenerateFileGraphics(this.item.Id);
    this.$store.dispatch('items/addProcessImageItemId', this.item.Id);
    this.HasItemBeenProcessed(this.item.Id);
  }

  handleQuickActionEvent(eventId: number) {
    if (eventId === 3 || eventId === 4) {
      eventId = this.item.IsApproved ? 3 : 4;
    }
    this.$refs.ItemActions.handleEvent(eventId);
  }
  grabbingHighRezImgChange(isLoading: boolean) {
    this.isGrabbingHiRezImage = isLoading;
  }
  showHighRez() {
    this.$refs.J1lImg.showHighRezDialog();
  }
  setHighRezOverlay(value: boolean) {
    this.isGrabbingHiRezImage = value;
  }
  refreshItem(itemId: string) {
    this.$emit('refreshItem', itemId);
  }

  handleEditItem(item: any) {
    this.$emit('handleEditItem', item);
  }
  setShouldItemRefreshToTrue(response: boolean) {
    this.$emit('setShouldItemRefreshToTrue', response);
  }
  rerenderGraphic() {
    this.$refs.J1lImg.rerender();
  }
  setAlertComponent(response: any) {
    this.$emit('setAlertComponent', response);
  }
  cancelProof() {}

  async orderItem(response: any) {
    this.setIsPlacingOrder(true);
    SetSnackBar('Ordering this item...');
    try {
      const { data } = await PaymentService.GetCustomerPaymentProfiles();
      const defaultPayment = data.filter((p: any) => p.IsDefault == true);
      let model = {
        Orderlines: response.orderline.map((ol: OrderlineDetails) =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        PO: response.selectedPO,
        State:
          response.orderline[0].OrderlinePurchaseStateType ===
          OrderlinePurchaseStateTypes.Create
            ? 'New'
            : 'Edit',
        ShipToId: 4,
        PaymentMethodProfileId: defaultPayment[0].PaymentProfileId,
        OrderType: 'Order Now'
      };
      const orderResponse = await OrdersService.PlaceOrder(model);
      const newOrderline: Orderline[] = orderResponse.data.map(
        (r: any) => new Orderline(r)
      );
      this.$store.dispatch('items/refreshItem', this.item.Id);
      await this.$store.dispatch('orderlines/ClearCurrentOrder');
      // this.addPOToList(
      //   new OrderPo({
      //     PO: response.selectedPO,
      //     Page: 1
      //   })
      // );
      this.$refs.ItemOrderDialog.closeDialog();
      SetSnackBar('Item ordered successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error ordering your item. Please contact techsupport@just1label.com'
      );
    }
    this.setIsPlacingOrder(false);
  }

  async addToCart({
    quantity,
    shippingAddressId
  }: {
    quantity: number;
    shippingAddressId: string;
  }) {
    const model = ConversionHelper.generateNewOrderlineModel(
      this.item.Id,
      quantity,
      shippingAddressId
    );
    await this.addToShoppingCart(model);
  }
  openAddToCartDialog() {
    this.$refs.itemAddToCart.openDialog();
  }
  openOrderNowDialog() {
    this.$refs.ItemOrderDialog.openDialog();
  }
  openApproval() {
    if (this.item.HasProofOnOrder) return;
    if (this.item.IsApproved) {
      this.$refs.ItemActions.handleEvent('Place on hold');
      return;
    }
    this.$refs.ItemActions.handleEvent('Approve Now');
  }
  /* Loaders */
  /* Computed */

  get getTooltipText() {
    return this.item.IsApproved
      ? 'Item is approved! Feel free to order this item at your convenience.'
      : 'This item is pending approval. Please make sure the file is sufficient before placing your order.';
  }
  get approvalActionText() {
    return this.item.IsApproved ? 'PUT ON HOLD' : 'APPROVE';
  }
  get itemApprovalEventId() {
    return this.item.IsApproved ? 3 : 4;
  }
  get customWidth() {
    return this.width || '300px';
  }
  /* Loaders */
  /* Mounted */
  async mounted() {}
  created() {}
  /* Created */
}
